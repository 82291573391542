import { PERMISSION_TYPE_READ, PERMISSION_TYPE_WRITE } from "./initialState";

export const applicableScopes = new Map([
  ["read_clients", ["clients", PERMISSION_TYPE_READ]],
  ["write_clients", ["clients", PERMISSION_TYPE_WRITE]],
  ["read_requests", ["requests", PERMISSION_TYPE_READ]],
  ["write_requests", ["requests", PERMISSION_TYPE_WRITE]],
  ["read_jobs", ["jobs", PERMISSION_TYPE_READ]],
  ["write_jobs", ["jobs", PERMISSION_TYPE_WRITE]],
  ["read_scheduled_items", ["scheduledItems", PERMISSION_TYPE_READ]],
  ["write_scheduled_items", ["scheduledItems", PERMISSION_TYPE_WRITE]],
  ["read_quotes", ["quotes", PERMISSION_TYPE_READ]],
  ["write_quotes", ["quotes", PERMISSION_TYPE_WRITE]],
  ["read_invoices", ["invoices", PERMISSION_TYPE_READ]],
  ["write_invoices", ["invoices", PERMISSION_TYPE_WRITE]],
  ["read_jobber_payments", ["jobberPayments", PERMISSION_TYPE_READ]],
  ["read_users", ["users", PERMISSION_TYPE_READ]],
  ["write_users", ["users", PERMISSION_TYPE_WRITE]],
  ["write_tax_rates", ["taxRates", PERMISSION_TYPE_WRITE]],
  ["read_expenses", ["expenses", PERMISSION_TYPE_READ]],
  ["write_expenses", ["expenses", PERMISSION_TYPE_WRITE]],
  [
    "read_custom_field_configurations",
    ["customFieldConfigurations", PERMISSION_TYPE_READ],
  ],
  [
    "write_custom_field_configurations",
    ["customFieldConfigurations", PERMISSION_TYPE_WRITE],
  ],
  ["read_time_sheets", ["timeSheets", PERMISSION_TYPE_READ]],
  ["read_equipment", ["equipment", PERMISSION_TYPE_READ]],
  ["write_equipment", ["equipment", PERMISSION_TYPE_WRITE]],
]);
