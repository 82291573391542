import React from "react";
import { ScopeItemType } from "./ScopeItem";

export const PERMISSION_TYPE_READ = "Read";
export const PERMISSION_TYPE_WRITE = "Write";

export interface ScopeCollection {
  [key: string]: ScopeItemType;
}

// The order of permissionTypes matters; first type will be selected as default & non editable
export const initialState: ScopeCollection = {
  clients: {
    key: "clients",
    label: "Clients",
    value: false,
    requiredScopes: [],
    permissionTypes: {
      [PERMISSION_TYPE_READ]: false,
      [PERMISSION_TYPE_WRITE]: false,
    },
    allowedAsOptional: false,
    optional: false,
  },
  requests: {
    key: "requests",
    label: "Requests",
    value: false,
    description: (
      <React.Fragment key="requests">
        Turn on <b key="requests">Clients</b> scope to access requests
      </React.Fragment>
    ),
    requiredScopes: ["read_clients"],
    permissionTypes: {
      [PERMISSION_TYPE_READ]: false,
      [PERMISSION_TYPE_WRITE]: false,
    },
    allowedAsOptional: false,
    optional: false,
  },
  quotes: {
    key: "quotes",
    label: "Quotes",
    value: false,
    description: (
      <React.Fragment key="quotes">
        Turn on <b key="quotes">Clients</b> scope to access quotes
      </React.Fragment>
    ),
    requiredScopes: ["read_clients"],
    permissionTypes: {
      [PERMISSION_TYPE_READ]: false,
      [PERMISSION_TYPE_WRITE]: false,
    },
    allowedAsOptional: false,
    optional: false,
  },
  jobs: {
    key: "jobs",
    label: "Jobs",
    value: false,
    description: (
      <React.Fragment key="jobs">
        Turn on <b key="jobs">Clients</b> scope to access jobs
      </React.Fragment>
    ),
    requiredScopes: ["read_clients"],
    permissionTypes: {
      [PERMISSION_TYPE_READ]: false,
      [PERMISSION_TYPE_WRITE]: false,
    },
    allowedAsOptional: false,
    optional: false,
  },
  scheduledItems: {
    key: "scheduledItems",
    label: "Scheduled Items",
    value: false,
    description: (
      <React.Fragment key="scheduledItems">
        Turn on <b key="scheduledItems">Clients</b> scope to access scheduled
        items
      </React.Fragment>
    ),
    additionalInformation:
      "Scheduled Items include Visits, Assessments, Tasks, and Calendar Events",
    requiredScopes: ["read_clients"],
    permissionTypes: {
      [PERMISSION_TYPE_READ]: false,
      [PERMISSION_TYPE_WRITE]: false,
    },
    allowedAsOptional: false,
    optional: false,
  },
  invoices: {
    key: "invoices",
    label: "Invoices",
    description: (
      <React.Fragment key="invoices">
        Turn on <b key="invoices">Clients</b> scope to access invoices
      </React.Fragment>
    ),
    value: false,
    requiredScopes: ["read_clients"],
    permissionTypes: {
      [PERMISSION_TYPE_READ]: false,
      [PERMISSION_TYPE_WRITE]: false,
    },
    allowedAsOptional: false,
    optional: false,
  },
  /* jobberPayments is the first instance of a scope that begins a chaining of dependent scopes.
     Adding "read_clients" in requiredScopes is a temporary solution.
     Should this particular situation happen again, we should revisit a new (possibly recursive)
     solution in `ScopeCollectionManipulators.ts` */
  jobberPayments: {
    key: "jobberPayments",
    label: "Jobber Payments",
    description: (
      <React.Fragment key="jobberPayments">
        Turn on <b key="jobberPayments">Invoices</b> scope to access Jobber
        Payments data
      </React.Fragment>
    ),
    value: false,
    requiredScopes: ["read_invoices", "read_clients"],
    permissionTypes: {
      [PERMISSION_TYPE_READ]: false,
    },
    allowedAsOptional: false,
    optional: false,
  },
  users: {
    key: "users",
    label: "Users",
    value: false,
    requiredScopes: [],
    permissionTypes: {
      [PERMISSION_TYPE_READ]: false,
      [PERMISSION_TYPE_WRITE]: false,
    },
    allowedAsOptional: false,
    optional: false,
  },
  taxRates: {
    key: "taxRates",
    label: "Tax Rates",
    value: false,
    requiredScopes: [],
    permissionTypes: {
      [PERMISSION_TYPE_WRITE]: false,
    },
    allowedAsOptional: false,
    optional: false,
  },
  expenses: {
    key: "expenses",
    label: "Expenses",
    value: false,
    additionalInformation:
      "Expenses are only available on Connect and Grow plans",
    requiredScopes: [],
    permissionTypes: {
      [PERMISSION_TYPE_READ]: false,
      [PERMISSION_TYPE_WRITE]: false,
    },
    allowedAsOptional: true,
    optional: false,
  },
  customFieldConfigurations: {
    key: "customFieldConfigurations",
    label: "Custom Field Configurations",
    value: false,
    additionalInformation:
      "Custom Field Configurations are only available on Connect and Grow plans",
    requiredScopes: [],
    permissionTypes: {
      [PERMISSION_TYPE_READ]: false,
      [PERMISSION_TYPE_WRITE]: false,
    },
    allowedAsOptional: true,
    optional: false,
  },
  timeSheets: {
    key: "timeSheets",
    label: "Timesheets",
    value: false,
    description: (
      <React.Fragment key="timeSheets">
        Turn on <b key="timeSheets">Users</b> scope to access time sheet data
      </React.Fragment>
    ),
    additionalInformation:
      "Timesheets are only available on Connect and Grow plans",
    requiredScopes: ["read_users"],
    permissionTypes: {
      [PERMISSION_TYPE_READ]: false,
    },
    allowedAsOptional: true,
    optional: false,
  },
  equipment: {
    key: "equipment",
    label: "Vehicles and Equipment",
    value: false,
    description: (
      <React.Fragment key="equipment">
        Turn on <b key="equipment">Users</b> scope to access vehicles and
        equipment data
      </React.Fragment>
    ),
    additionalInformation:
      "Vehicles and Equipment are only available on Connect and Grow plans",
    requiredScopes: ["read_users"],
    permissionTypes: {
      [PERMISSION_TYPE_READ]: false,
      [PERMISSION_TYPE_WRITE]: false,
    },
    allowedAsOptional: false,
    optional: false,
  },
};
